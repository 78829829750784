<template>
  <!-- 个人中心页面 -->
  <div>
   <Header/>
    <div class="main">
      <!-- 个人中心左侧导航-->
      <div class="left">
        <div class="left_header">
          <div class="left_header_img">
            <img
              :src="
                personImgUrl && personImgUrl !== ''
                  ? personImgUrl
                  : defaulHeader
              "
              alt=""
            />
          </div>
          <span style="width: 100px">{{ realname }}</span>
          <p class="left_header-study-time">
            {{ class_time != "" ? class_time : 0 }}课时&nbsp;
            {{ study_time != "" ? study_time : 0 }}分钟
          </p>
        </div>
        <!-- 头像下边的分割线 -->
        <div class="split_line"></div>



       <!-- 我的课程导航 -->
        <span  class="left_item left_item_one" @click="Mycourse">
          <p
            class="list"
            :class="mycourse ? 'write' : 'nowrite'"
            :style="{ color: mycourse == true ? '#2BADEF' : 'black' }"
          >
            我的课程
          </p>
        </span>
        <!-- 我的收藏导航 -->
        <!-- <span class="left_item" @click="Mycollect">
          <p
            class="list"
            :class="mycollect ? 'write' : 'nowrite'"
            :style="{ color: mycollect == true ? '#2BADEF' : 'black' }"
          >
            我的收藏
          </p>
        </span> -->
        <!-- 考试记录导航 -->
        <!-- <span  class="left_item" @click="History">
          <p
            class="list"
            :class="history ? 'write' : 'nowrite'"
            :style="{ color: history == true ? '#2BADEF' : 'black' }"
          >
            考试记录
          </p>
        </span> -->
        <!-- 我的证书导航 -->
        <span  class="left_item" @click="Mycertificate">
          <p
            class="list"
            :class="mycertificate ? 'write' : 'nowrite'"
            :style="{ color: mycertificate == true ? '#2BADEF' : 'black' }"
          >
            我的证书
          </p>
        </span>
        <!-- 个人设置导航 -->
        <span  class="left_item" @click="Settings">
          <p
            class="list"
            :class="settings ? 'write' : 'nowrite'"
            :style="{ color: settings == true ? '#2BADEF' : 'black' }"
          >
            个人设置
          </p>
        </span>
        <span  class="left_item">
          <p class="list">
          <router-link to="/" tag="div">返回首页</router-link>
          </p>
        </span>

      </div>
      <!-- 导航对应的模块 -->
      <div class="right">
        <!-- 我的课程模块 -->
        <div class="mycourse" v-if="mycourse">
          <div class="mycourse-year">
            <div class="mycourse-year-box">
              <el-date-picker
                @change="yearSelectCourse"
                class="mycourse-year-picker"
                v-model="SelectCourse"
                type="year"
                placeholder="按年份查找"
                ref="pickerRef"
              >
              </el-date-picker>
              <el-button class="reset-course" @click="resetCourse"
                >重置</el-button
              >
            </div>
          </div>
          <div style="display: flex; flex-wrap: wrap; margin-bottom: 26px">
            <div
              @click="
                $router.push(`/courdetails?cid=${item.id}&ct=${item.title}`)
              "
              class="course_item"
              v-for="(item,index) in mycourseList"
              :key="index"
            >
              <img class="course" :src="item.pic" alt="" />
              <p class="text_top">{{ item.title }}</p>
              <div style="overflow: hidden; margin-top: -10px;">
                <span class="text_bottom">已学习 {{ item.study_rate }}</span>
                <!-- <span
                  style="
                    font-size: 12px;
                    font-weight: bold;
                    float: right;
                    line-height: 20px;
                  "
                  >有效期至&nbsp;{{item.expiration_time.slice(0,10)}}</span
                > -->
                <!-- 截取时间中的年月日，从0开始到10 -->
              </div>
            </div>
          </div>
          <el-empty
            :image="require('../assets/images/no-data.png')"
            style="margin: 0 auto"
            v-show="courseEmpty"
          >
            <span slot="description"></span>
          </el-empty>
        </div>

        <!-- 底部分页器 -->
        <div class="pager" v-if="coursePaper">
          <el-pagination
            background
            style="text-align: center; margin-top: 0px; margin-bottom: 26px"
            layout="prev, pager, next"
            :total="course.count"
            class="page"
            :page-size="Number(course.page_size)"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChangeCourse"
            v-show="course.count > 12"
          >
          </el-pagination>
        </div>

        <!-- 我的收藏模块-->
        <div class="mycollect" v-if="mycollect">
          <div
            @click="
              $router.push(`/courdetails?cid=${item.id}&ct=${item.title}`)
            "
            class="collect_item"
            v-for="item in mycollectList"
            :key="item.id"
          >
            <div class="collect_img">
              <img class="collect" :src="item.pic" alt="" />
            </div>
            <div class="text_collect">
              <span class="text_top">{{ item.title }}</span>
              <img
                class="collect"
                @click.stop="cancelCollect(item)"
                src="../assets/images/collect.png"
                alt=""
              />
            </div>
          </div>
          <el-empty
            :image="require('../assets/images/no-data.png')"
            style="margin: 0 auto"
            v-show="colEmpty"
          >
            <span slot="description"></span>
          </el-empty>
        </div>
        <!-- 底部分页器 -->
        <div class="pager" v-if="collectPaper">
          <el-pagination
            background
            style="text-align: center; margin-top: 0px; margin-bottom: 26px"
            layout="prev, pager, next"
            :total="collect.count"
            class="page"
            :page-size="Number(collect.page_size)"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChangeColl"
            v-show="collect.count > 12"
          >
          </el-pagination>
        </div>

        <!-- 考试记录模块 -->
        <div class="history" v-if="history">
          <div class="history-year">
            <div class="history-year-box">
              <el-date-picker
                @change="yearSelectHistory"
                class="history-year-picker"
                v-model="SelectHistory"
                type="year"
                placeholder="按年份查找"
                :ref="yearSelectHistory"
              >
              </el-date-picker>
              <el-button class="reset-course" @click="resetHistory"
                >重置</el-button
              >
            </div>
          </div>

          <el-table
            @row-click="toResult"
            :data="historyList"
            style="width: 100%; margin: 0 auto; margin-bottom: 26px"
            :row-style="{ height: '60px' }"
            :header-cell-style="{
              background: '#8BB9FC',
              color: '#FFFFFF',
              fontSize: '16px',
              fontWidth: 500,
            }"
          >
            <span slot="empty"></span>
            <el-table-column
              prop="name"
              label="试卷名称"
              width="230"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="scores" label="分数" align="center">
            </el-table-column>
            <el-table-column prop="answer_time" label="考试用时" align="center">
              <template slot-scope="scope">{{
                formatSeconds(scope.row.answer_time)
              }}</template>
            </el-table-column>
            <el-table-column prop="is_pass" label="考试结果" align="center">
              <template slot-scope="scope">{{
                scope.row.is_pass == 1 ? "通过" : "未通过"
              }}</template>
            </el-table-column>
            <el-table-column prop="finish_time" label="考试时间" align="center">
            </el-table-column>
          </el-table>
          <el-empty
            :image="require('../assets/images/no-data.png')"
            style="margin: 0 auto"
            v-show="recordEmpty"
          >
            <span slot="description"></span>
          </el-empty>
        </div>

        <!-- 底部分页器 -->
        <div class="pager" v-if="historyPaper">
          <el-pagination
            background
            style="text-align: center; margin-top: 0px; margin-bottom: 26px"
            layout="prev, pager, next"
            :total="hiss.count"
            class="page"
            :page-size="Number(hiss.page_size)"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChangeHis"
            v-show="historyList.length > 10 && historyList.length > 10"
          >
          </el-pagination>
        </div>

        <!-- 我的证书模块 -->
        <div class="mycertificate" v-if="mycertificate">
          <div class="mycert-year">
            <div class="cert-year-box">
              <el-date-picker
                @change="yearSelectCert"
                class="cert-year-picker"
                v-model="SelectCert"
                type="year"
                placeholder="按年份查找"
                :ref="yearSelectCert"
              >
              </el-date-picker>
              <el-button class="reset-course" @click="resetCert"
                >重置</el-button
              >
            </div>
            <!-- <el-button type="primary" @click="verifyCert">生成证书</el-button> -->
          </div>
          <div
            class="cert_item"
            v-for="item in certList"
            :key="item.id"
            v-viewer="viewerOptions"
          >
            <img class="cert_item_img" :src="item.pic" alt="" />
            <p>{{ item.end_time }}</p>
          </div>
          <el-empty
            :image="require('../assets/images/no-data.png')"
            style="margin: 0 auto"
            v-show="certEmpty"
          >
            <span slot="description"></span>
          </el-empty>
        </div>
        <!-- 底部分页器 -->
        <div class="pager" v-if="certPaper">
          <el-pagination
            background
            style="text-align: center; margin-top: 0px; margin-bottom: 26px"
            layout="prev, pager, next"
            :total="cert.count"
            class="page"
            :page-size="Number(cert.page_size)"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChangeCert"
            v-show="certList.length > 15 && certList.length !== 0"
          >
          </el-pagination>
        </div>

        <!-- 个人设置模块 -->
        <div class="settings" v-if="settings">
          <div class="set">
            <div class="setheader">
              <span class="header_write"
                >头&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;像：</span
              >
              <div class="header_img">
                <label class="change_avatar" for="avatar">
                  <!--上传中有就是上传中的,没有先看存的信息，否则就默认的
                       <img :src="personImgUrl!=''?personImgUrl:personalForm!=''?personalForm:defaulHeader" alt=""> -->
                  <img
                    class="change_avatar_img"
                    :src="
                      personImgUrl && personImgUrl !== ''
                        ? personImgUrl
                        : personalForm.head_img != ''
                        ? personalForm.head_img
                        : defaulHeader
                    "
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="avatar"
                  hidden
                  @change="improveInfoImg"
                />
              </div>
            </div>
            <div class="realname">
              <span class="realname_left">真实姓名：</span>
              <input
                v-model="realname"
                class="realname_middle"
                disabled="disabled"
              >
            <!-- </input> -->
              <span class="realname_right" @click="setName">修改名字</span>
            </div>
            <div class="setcompany">
              <span class="company_left"
                >单&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位：</span
              >
              <input
                class="company_middle"
                disabled="disabled"
                v-model="company_name"
              />
              <span class="company_right" @click="setCompany">修改单位</span>
            </div>
            <div class="setphone">
              <span class="phone_left">手机号码：</span>
              <input
                class="phone_middle"
                disabled="disabled"
                v-model="setphone"
              />
              <span class="phone_right" @click="setPhone">修改手机</span>
            </div>
            <el-button class="logout" style="background: #fff">
              <el-popover placement="bottom" width="160" v-model="popShow">
                <p>确定退出登录吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="popShow = false"
                    >取消</el-button
                  >
                  <el-button type="primary" size="mini" @click="logout"
                    >确定</el-button
                  >
                </div>
                <el-button
                  type="text"
                  style="
                    margin-left: -30px;
                    background: #8bb9ff;
                    border-radius: 50px;
                    margin-bottom: 80px;
                  "
                  class="del-slot-btn user-list-operate"
                  slot="reference"
                >
                  退出登录
                </el-button>
              </el-popover>
            </el-button>
          </div>
        </div>

        <!-- 灰色遮罩 -->
        <div class="popup" v-if="popupMask">


          <!--修改姓名弹框 -->
          <div class="updateName" v-if="updateName">
            <div class="upCom_top">
              <span class="upCom_text">修改姓名</span>
              <img
                class="close_btn"
                @click="close_btn"
                src="../assets/images/close_btn.png"
                alt=""
              />
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">修改姓名</span>
              <el-col :span="48">
                <el-input
                  style="width: 100%"
                  class="inline-input"
                  v-model="upName_input"
                  placeholder="请输入修改后的姓名"
                ></el-input>
              </el-col>
            </div>
            <div class="upCom_bottom">
              <button class="cancel" @click="close_btn">取消</button>
              <button class="sure" @click="updateNa">确定</button>
            </div>
          </div>






          <!--修改单位弹框 -->
          <div class="updateCompany" v-if="updateCompany">
            <div class="upCom_top">
              <span class="upCom_text">修改单位</span>
              <img
                class="close_btn"
                @click="close_btn"
                src="../assets/images/close_btn.png"
                alt=""
              />
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">修改单位</span>
              <el-col :span="48">
                <!-- <el-autocomplete
                  value-key="name"
                  class="inline-input"
                  v-model="upCom_input"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入修改后的单位"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete> -->
                <el-input
                  style="width: 100%"
                  class="inline-input"
                  @blur="UpcheckCompany"
                  v-model="upCom_input"
                  placeholder="请务必输入修改后企业全称"
                ></el-input>
              </el-col>
              <!-- <el-input
                v-model="upCom_input"
                placeholder="请输入修改后的单位"
              ></el-input> -->
            </div>
            <div
              style="
                width: 100%;
                height: 20px;
                text-align: center;
              "
            >
              <b v-show="companyOne" style="color: red; font-size: 12px"
                >请输入企业名称</b
              >
              <b v-show="companyTwo" style="color: red; font-size: 12px"
                >请输入企业全称</b
              >
            </div>
            <div class="upCom_bottom">
              <button class="cancel" @click="close_btn">取消</button>
              <button class="sure" @click="updateCom">确定</button>
            </div>
          </div>
          <!-- 修改手机号弹窗 -->
          <div class="updatePhone" v-if="updatePhone">
            <div class="upCom_top">
              <span class="upCom_text">修改手机号</span>
              <img
                class="close_btn"
                @click="close_btn"
                src="../assets/images/close_btn.png"
                alt=""
              />
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">手机号</span>
              <el-input
                class="upCom_input_phone"
                v-model="setphone"
                placeholder="请输入旧手机号"
              ></el-input>
              <el-button
                class="getCode"
                :disabled="btnDisabled"
                @click="getCode"
                >获取验证码
                {{ phoneBtnCount == 60 ? "" : phoneBtnCount + "s" }}</el-button
              >
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">验证码</span>
              <el-input
                class="upCom_input_code"
                v-model="phoneForm.code"
                placeholder="请输入短信验验证码"
              ></el-input>
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">新手机</span>
              <el-input
                class="upCome_input_new"
                v-model="phoneForm.new_mobile"
                placeholder="请输入新手机号"
              ></el-input>
            </div>
            <div class="upCom_bottom">
              <button class="cancel" @click="close_btn">取消</button>
              <button class="sure" @click="updatePho">确定</button>
            </div>
          </div>
        </div>
        <!-- 底部分页器 -->
        <!-- <div class="pager" v-if="paper">
          <el-pagination
            background
            style="text-align: center; margin-top: 10px; margin-bottom: 10px"
            layout="prev, pager, next"
            :total="1000"
            class="page"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
    <div class="c-b">
      <div class="certificate" ref="certificate">
        <span class="cert-name">{{ certInfo.name }}</span>
        <span class="cert-stime">{{ certInfo.start_time }}</span>
        <span class="cert-etime">{{ certInfo.end_time }}</span>
        <span class="cert-course-time">{{ certInfo.class_num }}</span>
        <span class="cert-identifier">{{ certInfo.cert_num }}</span>
        <span class="cert-ftime">{{ certInfo.send_time }}</span>
        <!-- <span class="cert-cname">{{ certInfo.cert_name }}</span> -->
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Foot from "../components/Foot.vue";
import defaulHeader from "../assets/images/default_myhead.png";
import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";
import html2canvas from "html2canvas";

export default {
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {
      tabPosition: "left", //框架自带
      course: {}, //课程data
      mycourse: true, //我的课程-模块
      mycourseList: [], //我的课程
      coursePaper: true, //我的课程-分页
      collect: {}, //收藏data
      mycollect: false, //我的收藏模块
      mycollectList: [], //我的收藏
      collectPaper: false, //我的收藏-分页
      hiss: {}, //记录data
      history: false, //考试记录模块
      historyList: [], //考试记录
      historyPaper: false, //考试记录-分页
      cert: {}, //证书data
      certList: [],
      mycertificate: false, //我的证书模块,
      certPaper: false, //我的证书-分页
      settings: false, //个人设置模块
      defaulHeader: defaulHeader, //默认显示头像路径
      personImgUrl: "", //上传时显示的路
      updateName: false, //修改姓名弹框
      updateCompany: false, //修改单位弹框
      upName_input: "", //修改-姓名-v-model
      upCom_input: "", //修改-单位-v-model
      companyOne: false, //提示请输入企业名称
      companyTwo: false, //提示请输入企业全称
      popupMask: false, //灰色遮罩
      updatePhone: false, //修改手机号弹框
      phoneBtnCount: 60, //验证码的60秒
      btnDisabled: false, //控制验证码是否能点击
      phoneForm: {
        //修改手机号表单v-model
        mobile: "",
        code: "",
        new_mobile: "",
      },
      personalForm: {
        head_img: "",
      },
      viewerOptions: {
        movable: false,
        title: false,
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          prev: 4,
          play: false,
          next: 4,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 4,
          flipVertical: 4,
        },
      },
      realname: "",
      company_name: "",
      setphone: "",
      popShow: false,
      state1: "",
      state2: "",
      companyList: [],
      hanSelect: null, //下边的选项
      currentPage: 1, //默认显示第一页
      colEmpty: false,
      recordEmpty: false,
      certEmpty: false,
      courseEmpty: false,
      study_time: "",
      class_time: "",

      SelectCourse: "", //按照年份查找
      displayValueH: "",
      SelectHistory: "", //按照年份查找
      displayValueC: "",
      SelectCert: "", //按照年份查找
      displayValueT: "",
      displayValue:"",

      // yearSelectCert:"",//按照年份查找
      certInfo: {},
    };
  },
  mounted() {
    console.log(this.SelectCourse)
      function bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可视区域宽度
      var scale = devicewidth / 1920; // 分母——设计稿的尺寸
      // document.body.style.zoom = scale; //放大缩小相应倍数

    }
     console.log(this.$route.query)
      if(this.$route.query.id=="1"&&localStorage.getItem("certNumber")==1){
              //  this.$router.push(`/personal?id=${1}`)
                // console.log( window.location.href);
                localStorage.setItem("certNumber",1)
                this.mycourse = true;
                this.mycollect = false;
                this.history = false;
                this.mycertificate = false;
                this.settings = false;
                this.getmyCourse();
                this.getPower();//判断是否走后门进来的
                this.coursePaper = true;
                this.collectPaper = false;
                this.historyPaper = false;
                this.certPaper = false;

                this.certEmpty = false;
                this.SelectCert = "";

                this.recordEmpty = false;
                this.SelectHistory = "";
      }
      if(this.$route.query.id=="2"&&localStorage.getItem("certNumber")==2){
           // this.$router.push(`/personal?id=${2}`)
            this.history = true;
            this.mycourse = false;
            this.mycollect = false;
            this.mycertificate = false;
            this.settings = false;
            this.getHistory();
            this.historyPaper = true;
            this.coursePaper = false;
            this.collectPaper = false;
            this.certPaper = false;

            this.courseEmpty = false;
            this.SelectCourse = "";

            this.certEmpty = false;
            this.SelectCert = "";
      }
      if(this.$route.query.id=="3"&&localStorage.getItem("certNumber")==3){
          //this.$router.push(`/personal?id=${3}`)
          console.log('进入我的证书页面');
          localStorage.setItem("certNumber",3);
          this.mycertificate = true;
          this.mycourse = false;
          this.mycollect = false;
          this.history = false;
          this.settings = false;
          this.certPaper = true;
          this.coursePaper = false;
          this.collectPaper = false;
          this.historyPaper = false;
          this.getmyCert();
          this.courseEmpty = false;
          this.SelectCourse = "";

          this.recordEmpty = false;
          this.SelectHistory = "";
      }
      if(this.$route.query.id="4"&&localStorage.getItem("certNumber")==4){
        //  this.$router.push(`/personal?id=${4}`)
          localStorage.setItem("certNumber",4)
          this.settings = true;
          this.mycourse = false;
          this.mycollect = false;
          this.history = false;
          this.mycertificate = false;
          this.certPaper = false;
          this.coursePaper = false;
          this.collectPaper = false;
          this.historyPaper = false;
          this.courseEmpty = false;
          this.SelectCourse = "";

          this.certEmpty = false;
          this.SelectCert = "";

          this.recordEmpty = false;
          this.SelectHistory = "";
      }
    bodyScale();
    this.getPersonalSetData(); //首页自动请求个人设置
    this.getmyCourse();
    this.getheadData();
    this.getPower();//判断是否走后门进来的

  },
  methods: {
    // 调用判断是否有看这个课程的权限
    getPower(){
       this.axios.get("index/user_free_status").then((res)=>{
          // console.log(res);
       })
       },
    toResult(row) {
      this.$router.push(`/results?pid=${row.id}&etitle=${row.name}`);
    },
    Mycourse() {
      this.$router.push(`/personal?id=${1}`)
      // console.log( window.location.href);
      localStorage.setItem("certNumber",1)
      this.mycourse = true;
      this.mycollect = false;
      this.history = false;
      this.mycertificate = false;
      this.settings = false;
      this.getmyCourse();
      this.getPower();//判断是否走后门进来的
      this.coursePaper = true;
      this.collectPaper = false;
      this.historyPaper = false;
      this.certPaper = false;

      this.certEmpty = false;
      this.SelectCert = "";

      this.recordEmpty = false;
      this.SelectHistory = "";
    },
    Mycollect() {

      localStorage.setItem("certNumber",2)
      this.mycollect = true;
      this.mycourse = false;
      this.history = false;
      this.mycertificate = false;
      this.settings = false;
      this.getmyCollection();
      this.collectPaper = true;
      this.coursePaper = false;
      this.historyPaper = false;
      this.certPaper = false;

      this.courseEmpty = false;
      this.SelectCourse = "";

      this.certEmpty = false;
      this.SelectCert = "";

      this.recordEmpty = false;
      this.SelectHistory = "";
    },
    History() {
      this.$router.push(`/personal?id=${2}`)
      localStorage.setItem("certNumber",2)
      this.history = true;
      this.mycourse = false;
      this.mycollect = false;
      this.mycertificate = false;
      this.settings = false;
      this.getHistory();
      this.historyPaper = true;
      this.coursePaper = false;
      this.collectPaper = false;
      this.certPaper = false;

      this.courseEmpty = false;
      this.SelectCourse = "";

      this.certEmpty = false;
      this.SelectCert = "";
    },
    Mycertificate() {
      this.$router.push(`/personal?id=${3}`)
      console.log('进入我的证书页面');
      localStorage.setItem("certNumber",3);
      this.mycertificate = true;
      this.mycourse = false;
      this.mycollect = false;
      this.history = false;
      this.settings = false;
      this.certPaper = true;
      this.coursePaper = false;
      this.collectPaper = false;
      this.historyPaper = false;
      this.getmyCert();
      this.courseEmpty = false;
      this.SelectCourse = "";

      this.recordEmpty = false;
      this.SelectHistory = "";
    },
    Settings() {
      this.$router.push(`/personal?id=${4}`)
      localStorage.setItem("certNumber",4)
      this.settings = true;
      this.mycourse = false;
      this.mycollect = false;
      this.history = false;
      this.mycertificate = false;
      this.certPaper = false;
      this.coursePaper = false;
      this.collectPaper = false;
      this.historyPaper = false;
      this.courseEmpty = false;
      this.SelectCourse = "";

      this.certEmpty = false;
      this.SelectCert = "";

      this.recordEmpty = false;
      this.SelectHistory = "";
    },
    //获取个人设置请求
    getPersonalSetData() {
      this.axios.post("index/my_settings").then((res) => {
        console.log(res);
        this.realname = res.data.user_info[0].name;
        this.company_name = res.data.user_info[0].company_name;
        this.setphone = res.data.user_info[0].mobile;
        this.personImgUrl = res.data.user_info[0].head_img;
        this.study_time = res.data.user_info[0].study_time;
        this.class_time = res.data.user_info[0].class_time;
        let obj = {};
        obj.company_name = res.data.user_info[0].company_name;
        obj.name = res.data.user_info[0].name;
        obj.head_img = res.data.user_info[0].head_img;
        obj.id = res.data.user_info[0].id;
        this.$store.dispatch("SET_EMPLOYEE_INFO", JSON.stringify(obj));
      });
    },
    //获取我的课程请求
    getmyCourse() {
      let fd = new FormData();
      fd.append("page_size", 12);
      this.axios.post("index/my_course", fd).then((res) => {
        // if(this.mycourseList.length<=12){
        //   this.coursePaper=true
        // }
        console.log(res);
        this.course = res.data;
        this.mycourseList = res.data.list;
        if (res.data.count == 0) {
          this.courseEmpty = true;
        }
      });
    },
    //我的课程更改页码
    handleCurrentChangeCourse(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 12);
      this.axios.post("index/my_course", fd).then((res) => {
        console.log(res);
        this.course = res.data;
        this.mycourseList = res.data.list;
      });
    },
    //我的课程--年份查询!!!
    yearSelectCourse(e, y) {
      console.log(e);
      console.log(y);
        let yy = new Date(e).getFullYear();
console.log(yy)

      let fd = new FormData();
      fd.append("page_size", 12);
      fd.append("year", yy);
      this.axios.post("index/my_course", fd).then((res) => {
        console.log(res);
        console.log(e);

        // this.displayValueC=this.$children[0].displayValue;
        console.log(this.$parent.$children);
        this.course = res.data;
        this.mycourseList = res.data.list;
        if (res.data.count == 0) {
          this.courseEmpty = true;
          this.coursePaper = false;
        } else {
          if (res.data.count <= 12) {
            this.coursePaper = false;
            this.courseEmpty = false;
          } else {
            this.courseEmpty = false;
            this.coursePaper = true;
          }
        }
      });
    },
    resetCourse() {
      this.SelectCourse = "";
      let fd = new FormData();
      fd.append("page_size", 12);
      this.axios.post("index/my_course", fd).then((res) => {
        console.log(res);
        this.course = res.data;
        this.mycourseList = res.data.list;
        if (res.data.count == 0) {
          this.courseEmpty = true;
        } else {
          if (res.data.count > 12) {
            this.courseEmpty = false;
            this.coursePaper = true;
          } else {
            this.coursePaper = false;
            this.courseEmpty = false;
          }
        }
      });
    },
    //获取我的收藏请求
    getmyCollection() {
      let fd = new FormData();
      fd.append("page_size", 12);
      this.axios.post("index/my_collection", fd).then((res) => {
        console.log(res);
        // if(this.mycollectList>12){
        //    this.collectPaper=true;
        // }
        this.collect = res.data;
        this.mycollectList = res.data.list;
        if (res.data.count == 0) {
          this.colEmpty = true;
        }
      });
    },
    //我的收藏更改页码
    handleCurrentChangeColl(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 12);
      this.axios.post("index/my_collection", fd).then((res) => {
        this.collect = res.data;
        this.mycollectList = res.data.list;
      });
    },
    //取消收藏请求
    cancelCollect(item) {
      let fd = new FormData();
      fd.append("course_id", item.id);
      fd.append("is_collection", "0");
      this.axios.post("index/is_collection", fd).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.mycollectList = this.mycollectList.filter((ele) => {
            return ele.id !== item.id;
          });
          console.log(res);
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //获取考试记录请求
    getHistory() {
      let fd = new FormData();
      fd.append("page_size", 10);
      this.axios.post("/exam/exam_record", fd).then((res) => {
        console.log(res);
        if (this.historyList > 10) {
          this.historyPaper = true;
        }
        this.hiss = res.data;
        this.historyList = res.data.res;
        if (res.data.count == 0) {
          this.recordEmpty = true;
        }
      });
    },
    //考试记录--年份查询!!!
    yearSelectHistory(e) {
         let yy = new Date(e).getFullYear();
console.log(yy)
      let fd = new FormData();
      fd.append("page_size", 10);
      fd.append("year", yy);
      this.axios.post("/exam/exam_record", fd).then((res) => {
        console.log(res);
        console.log(e);
        console.log(this.$children);

        // this.displayValueH=this.$children[0].displayValue
        this.hiss = res.data;
        this.historyList = res.data.res;
        if (res.data.count == 0) {
          this.recordEmpty = true;
        } else {
          this.recordEmpty = false;
        }
      });
    },
    resetHistory() {
      this.getHistory();
      this.recordEmpty = false;
      this.SelectHistory = "";
    },
    //考试记录更改页码
    handleCurrentChangeHis(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 10);
      this.axios.post("/exam/exam_record", fd).then((res) => {
        this.hiss = res.data;
        this.historyList = res.data.res;
      });
    },
    //我的证书请求
    getmyCert() {
      let fd = new FormData();
      fd.append("page_size", 15);
      this.axios.post("/index/my_cert", fd).then((res) => {
        console.log(res);
        this.cert = res.data;
        this.certList = res.data.list;
        if (res.data.list == 0) {
          this.certEmpty = true;
        }
      });
    },
    //我的证书-年份查询
    yearSelectCert(e) {
      let yy = new Date(e).getFullYear();
console.log(yy)
      let fd = new FormData();
      fd.append("page_size", 15);
      fd.append("year", yy);
      this.axios.post("/index/my_cert", fd).then((res) => {
        console.log(res);
        console.log(e)

        // this.displayValueH=this.$children[0].displayValue
        this.cert = res.data;
        this.certList = res.data.list;
        if (res.data.list.length == 0) {
          this.certEmpty = true;
        } else {
          this.certEmpty = false;
        }
      });
    },
    resetCert() {
      this.getmyCert();
      this.certEmpty = false;
      this.SelectCert = "";
    },
    //我的证书更改页码
    handleCurrentChangeCert(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", 15);
      this.axios.post("/index/my_cert", fd).then((res) => {
        this.cert = res.data;
        this.certList = res.data.list;
      });
    },
    //点关闭叉号时清除表单
    close_btn() {
      this.updateName = false;
      this.updateCompany = false;
      this.popupMask = false;
      this.upName_input = "";
      this.upCom_input = "";
      // this.phoneForm.mobile="";
      this.phoneForm.code = "";
      this.phoneForm.new_mobile = "";
      this.companyOne=false;
      this.companyTwo=false;
    },
    //修改头像的上传文件
    improveInfoImg(e) {
      this.personalForm.head_img = e.target.files[0];
      let fr = new FileReader();
      fr.readAsDataURL(e.target.files[0]);
      fr.onload = () => {
        this.personImgUrl = fr.result;
      };
      this.updateHeader(); //改完了,发送修改头像接口
      this.getPersonalSetData(); //修改完了请求个人设置页面接口，刷新页面
    },
    //修改头像请求(user_id)
    updateHeader() {
      let fd = new FormData();
      fd.append("user_id", JSON.parse(this.$store.state.empInfo).id);
      fd.append("head_img", this.personalForm.head_img);
      this.axios.post("/login/update_headimg", fd).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.getPersonalSetData(); //修改完了请求个人设置页面接口，刷新页面
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //请求头部数据
    getheadData() {
      this.axios.post("/").then((res) => {});
    },
    //修改姓名弹出
    setName(){
      this.updateName = true;
      this.popupMask = true;
    },
    // 确认修改姓名
    updateNa(){
     let fd = new FormData();
      fd.append("user_id", JSON.parse(this.$store.state.empInfo).id);
      fd.append("value", this.upName_input);
      fd.append("field", "name");
      this.axios.post("login/update_info", fd).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.updateName = false;
          this.popupMask = false;
          console.log(res);
          this.getPersonalSetData(); //改完了刷新设置页面
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //修改单位名称弹出
    setCompany() {
      this.updateCompany = true;
      this.popupMask = true;
      this.updatePhone = false;
      // this.loadAll();
    },
    // 修改单位校验
    UpcheckCompany() {
      if (this.upCom_input === "") {
        this.companyOne = true;
      } else {
        this.companyOne = false;
      }
      if (this.upCom_input !== "" && this.upCom_input.length < 7) {
        this.companyTwo = true;
      } else {
        this.companyTwo = false;
      }
      // console.log(this.resgisterForm.company.length);
    },
    //修改单位请求(user_id)-----确定修改时候
    updateCom() {
      // console.log(this.upCom_input);
       if (this.upCom_input === "") {
        this.companyOne = true;
        return false
      } else {
        this.companyOne = false;
      }
      if (this.upCom_input !== "" && this.upCom_input.length < 7) {
        this.companyTwo = true;
        return false
      } else {
        this.companyTwo = false;
      }
      let fd = new FormData();
      fd.append("user_id", JSON.parse(this.$store.state.empInfo).id);
      fd.append("value", this.upCom_input);
      fd.append("field", "company");
      this.axios.post("login/update_info", fd).then((res) => {
        console.log(res);

        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          console.log(res);
          this.getPersonalSetData(); //改完了刷新设置页面
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
      this.updateCompany = false;
      this.popupMask = false;
    },
    // updateCom() {
    //   if (!this.hanSelect) {
    //     let obj = this.companyList.filter((item) => {
    //       console.log(item);
    //       return item.name === this.upCom_input;
    //     });
    //     console.log(obj);
    //     if (obj[0]) {
    //       let fd = new FormData();
    //       fd.append("user_id", JSON.parse(this.$store.state.empInfo).id);
    //       fd.append("c_id", obj[0].id);
    //       this.axios.post("login/update_company", fd).then((res) => {
    //         console.log(res);
    //         if (res.data.code == 0) {
    //           this.$message({
    //             type: "success",
    //             message: res.data.msg,
    //           });
    //           console.log(res);
    //           this.getPersonalSetData(); //改完了刷新设置页面
    //           this.updateCompany = false;
    //           this.popupMask = false;
    //         } else {
    //           this.$message({
    //             type: "error",
    //             message: res.data.msg,
    //           });
    //         }
    //       });
    //     } else {
    //       this.$message.error("您输入的公司不匹配");
    //       return false;
    //     }
    //   } else {
    //     // console.log(this.upCom_input);
    //     let fd = new FormData();
    //     fd.append("user_id", JSON.parse(this.$store.state.empInfo).id);
    //     fd.append("c_id", this.hanSelect.id);
    //     this.axios.post("login/update_company", fd).then((res) => {
    //       console.log(res);

    //       if (res.data.code == 0) {
    //         this.$message({
    //           type: "success",
    //           message: res.data.msg,
    //         });
    //         console.log(res);
    //         this.getPersonalSetData(); //改完了刷新设置页面
    //       } else {
    //         this.$message({
    //           type: "error",
    //           message: res.data.msg,
    //         });
    //       }
    //     });
    //     this.updateCompany = false;
    //     this.popupMask = false;
    //   }
    // },
    //修改单位的下拉建议------自带的下拉
    // querySearch(queryString, cb) {
    //   let fd = new FormData();
    //   fd.append("keyword", this.upCom_input);
    //   this.axios.post("login/keyword_matching", fd).then((res) => {
    //     console.log(res);
    //     if (res.data.code !== 1) {
    //       cb(res.data);
    //     } else {
    //       cb([]);
    //     }
    //   });
    //   // 调用 callback 返回建议列表的数据
    // },
    // loadAll() {
    //   this.axios.post("/login/company").then((res) => {
    //     console.log(res);
    //     this.companyList = res.data;
    //   });
    //   return this.restaurants;
    //   // return [
    //   //   { "value": "三全鲜食（北新泾店）" },
    //   //   { "value": "Hot honey 首尔炸鸡（仙霞路）"},
    //   //   { "value": "新旺角茶餐厅"},
    //   //   { "value": "泷千家(天山西路店)"},
    //   //   { "value": "胖仙女纸杯蛋糕（上海凌空店）"},
    //   // ];
    // },
    // handleSelect(item) {
    //   this.hanSelect = item;
    //   console.log(item);
    // },
    //修改手机号弹出
    setPhone() {
      this.updatePhone = true;
      this.popupMask = true;
      this.updateCompany = false;
    },
    //修改手机号的验证码请求
    getCode() {
      if (this.setphone == "") {
        this.$message({
          type: "error",
          message: "请输入手机号",
        });
        return false;
      }
      if (this.setphone.length != 11) {
        this.$message({
          type: "error",
          message: "请输入正确的手机号",
        });
        return false;
      }
      this.btnDisabled = true; //让按钮可以点
      let fd = new FormData();
      fd.append("mobile", this.setphone);
      this.axios.post("/login/sendcode", fd).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
      let timer = setInterval(() => {
        this.phoneBtnCount--;
        if (this.phoneBtnCount == 0) {
          clearInterval(timer);
          this.phoneBtnCount = 60;
          this.btnDisabled = false;
        }
      }, 1000);
    },
    //修改手机号请求(user_id)
    updatePho() {
      console.log(JSON.parse(this.$store.state.empInfo).id);

      let fd = new FormData();
      fd.append("user_id", JSON.parse(this.$store.state.empInfo).id);
      fd.append("mobile", this.setphone);
      fd.append("code", this.phoneForm.code);
      fd.append("new_mobile", this.phoneForm.new_mobile);
      this.axios.post("/login/update_phone", fd).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.updatePhone = false;
          this.getPersonalSetData(); //重新请求个人设置
          this.popupMask = false;
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //确定退出登录
    logout() {
      //this.popShow=true;
      this.axios.post("/login/login_out").then((res) => {
        localStorage.removeItem("eft");
        // localStorage.removeItem("employee_info");
        this.$store.dispatch("SET_EMPLOYEE_INFO", JSON.stringify({}));
        // localStorage.removeItem("business_info");
        this.$router.push("/");
      });
    },
    // 秒转分秒
    formatSeconds(time) {
      console.log(time);
      let result = parseInt(time);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);

      return m + ":" + s;
    },
    verifyCert() {
      this.axios.post("exam/apply_class_cert").then((res) => {
        console.log(res);

        if (res.data.code == 1) {
          this.$message.error(res.data.msg);
        } else {
          this.certInfo = res.data.info;
          setTimeout(() => {
            this.createCert();
          }, 800);
        }
      });
    },
    createCert() {
      html2canvas(this.$refs.certificate).then((canvas) => {
        console.log(canvas);

        let base64 = canvas.toDataURL("image/png");
        let fd = new FormData();
        fd.append("pic", base64);
        this.axios.post("/exam/cert_pic", fd).then((res) => {
          this.$message.success("恭喜您申请证书成功!");
          this.$router.go(0)
        });
      });
    },
  },
  components: { Header,Foot},
};
</script>

<style lang="scss">
body {
  background: #f8f8f8;
}
.realname {
  .el-input__inner {
    height: 30px;
  }
}
.el-tabs__active-bar {
  background: none;
}
.right {
  /* el-table表格去掉横线 */
  .el-table__row > td {
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
  .logout {
    .el-button--text {
      width: 150px;
      height: 50px;
      font-size: 15px;
      color: #fff;
      padding-bottom: 12px;
      border-radius: 0;
    }
  }
}

.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
.upCom_middle {
  .el-input__inner {
    width: 150%;
  }
}
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .viewer-transition {
    padding-left: 200px;
    padding-top: 60px;
  }
}
@media screen and (max-width: 1380px) {
  .viewer-transition {
    padding-left: 320px;
    padding-top: 80px;
  }
}
</style>

<style lang="scss" scoped>
body {
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
}
.main {
  width: 60.3%;
  height: 100%;
  //background: rgb(117, 117, 116);
  // background: #f8f8f8;
  margin: 0 auto;
  padding-top: 44px;
  display: flex;
  //左侧导航
  .left {
    width: 20%;
    height: 41%;
    // background: rgb(223, 223, 180);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding-bottom: 50px;
    .left_header {
      // width: 74px;
      // background: lightsteelblue;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      margin-top: -20px;
      text-align: center;
      margin-bottom: 30px;
      border: 0px;
      .left_header_img {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        border: 0px;

        img {
          width: 74px;
          height: 74px;
          border-radius: 50%;
          border: 0px;
          overflow: hidden;
          object-fit: cover; //替换图片的时候保持比例
          // background: url(../assets/images/default_header.png) center center
          // no-repeat;
          background-size: 100px 100px;
        }
        img:not([src]) {
          opacity: 0;
        }
      }
      span {
        margin-top: 15px;
      }
      .left_header-study-time {
        margin: 0;
        margin-top: 10px;
        font-size: 14px;
      }
    }
    .split_line {
      width: 100%;
      height: 1px;
      background: rgb(228, 230, 230);
      margin-bottom: 10px;
    }
    .left_item {
      width: 80px;
      height: 60px;
      text-align: center;
      margin-top: -5px;
      font-size: 16px;
      margin: 0 auto;
      .list {
        cursor: pointer;
        padding-bottom: 8px;
      }
      .write {
        border-bottom: 1.5px solid #2badef;
      }
      .nowrite {
        border-bottom: 1.5px solid #fff;
      }
    }
  }
  //右侧切换模块
  .right {
    width: 100%;
    height: 100%;
    // background: darksalmon;
    background: #ffffff;
    margin-bottom: 20px;
    .mycourse {
      width: 100%;
      height: 100%;
      // background: rgb(192, 185, 156);
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding-bottom: 218px;
      @media screen and (max-width: 1380px) {
        .course_item {
          width: 220px;
          height: 190px;
          //  background: darksalmon;
          margin-left: 12px;
          margin-top: 12px;
          .text_top {
            font-size: 16px;
            color: #333333;
            margin-top: 9px;
            margin-bottom: 20px;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text_bottom {
            color: #666666;
            font-size: 14px;
            margin-top: -15px;
          }
          .course {
            width: 225px;
            height: 134px;
          }
        }
      }
      @media screen and (min-width: 1555px) {
        .course_item {
          width: 225px;
          height: 190px;
          //  background: darksalmon;
          margin-left: 12px;
          margin-top: 12px;
          .text_top {
            font-size: 16px;
            color: #333333;
            margin-top: 9px;
            margin-bottom: 20px;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text_bottom {
            color: #666666;
            font-size: 14px;
            margin-top: -15px;
          }
          .course {
            width: 225px;
            height: 134px;
          }
        }
      }
      @media screen and (max-width: 1555px) and (min-width: 1380px) {
        .course_item {
          width: 220px;
          height: 190px;
          //  background: darksalmon;
          margin-left: 12px;
          margin-top: 12px;
          .text_top {
            font-size: 16px;
            color: #333333;
            margin-top: 9px;
            margin-bottom: 20px;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text_bottom {
            color: #666666;
            font-size: 14px;
            margin-top: -15px;
          }
          .course {
            width: 225px;
            height: 134px;
          }
        }
      }
      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .mycourse-year {
        width: 100%;
        height: 100%;
        padding-top: 26px;
        padding-bottom: 14px;
        //background: pink;
        overflow: hidden;
        .mycourse-year-box {
          width: 430px;
          // height: 100%;
          margin: 0 auto;
          //background: greenyellow;
          .mycourse-year-picker {
            width: 300px;
            margin-left: 12px;
            // margin-top: 10px;
          }
          .reset-course {
            margin-left: 20px;
            // margin-top: 10px;
          }
        }
      }
    }
    .mycollect {
      width: 100%;
      height: 100%;
      //background: rgb(192, 185, 156);
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      margin-bottom: 25px;
      .collect_item {
        .collect_img {
          width: 225px;
          height: 134px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        width: 225px;
        height: 170px;
        // background: darksalmon;
        margin-left: 10.9px;
        margin-top: 26px;
        .text_collect {
          display: flex;
          justify-content: space-between;
          .collect {
            width: 17px;
            height: 17px;
            vertical-align: text-bottom;
            margin-left: 5px;
            margin-top: 10px;
            cursor: pointer;
          }
          .text_top {
            font-size: 16px;
            //  color: #333333;
            margin-top: 8px;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .text_bottom {
          //  color: #666666;
          font-size: 14px;
          margin-top: -15px;
        }
      }
      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    .history {
      //width: 100%;
      height: 1000px;
      font-size: 14px;
      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .history-year {
        //background: grey;
        margin-top: 16px;
        margin-bottom: 26px;
        .history-year-box {
          width: 500px;
          height: 100%;
          //background:lemonchiffon;
          margin: 0 auto;
          .history-year-picker {
            width: 300px;
            margin-left: 12px;
            margin-top: 10px;
          }
          .reset-course {
            margin-left: 20px;
            margin-top: 10px;
          }
        }
      }
    }
    .mycertificate {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      padding-top: 26px;
      margin-bottom: 305px;
      .cert_item {
        width: 158px;
        // height: 220px;
        margin-left: 20px;
        margin-right: 12px;
        font-size: 14px;
        text-align: center;
        // background: lemonchiffon;
        //  margin-bottom: 40px;
        margin-top: 10px;
        .cert_item_img {
          margin-bottom: -12px;
          width: 100%;
          cursor: pointer;
        }
        p {
          margin: 0;
          margin-top: 15px;
        }
      }
      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .mycert-year {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background: grey;
        // margin-top: -5px;
        margin-bottom: 6px;
        // padding: 0 15px;
        padding-right: 20px;
        .cert-year-box {
          // width: 50%;
          .cert-year-picker {
            width: 300px;
            margin-left: 12px;
            //  margin-top: 10px;
          }
          .reset-course {
            margin-left: 20px;
            // margin-top: 10px;
          }
        }
      }
    }
  }
  //个人设置
  .settings {
    width: 100%;
    //height: 1000px;
    .set {
      width: 100%;
      height: 100%;
      .setheader {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        .header_write {
          margin-left: 45px;
        }
        .header_img {
          margin-left: 40px;
          border: 0px;
          .change_avatar {
            display: block;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            text-align: center;
            margin: 0 auto;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            .change_avatar_img {
              width: 100%;
              height: 100%;
              border: 0px solid transparent;
              object-fit: cover; //替换图片的时候保持比例
            }
            img:not([src]) {
              opacity: 0;
            }
            &:hover {
              &::after {
                content: "编辑头像";
                font-size: 15px;
                display: block;
                width: 100%;
                height: 40px;
                line-height: 32px;
                color: #fff;
                border-radius: 0 0 100px 100px;
                background-color: rgba($color: rgb(39, 37, 37), $alpha: 0.4);
                position: absolute;
                bottom: 0;
              }
            }
          }
        }
      }
      .realname {
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        .realname_left {
          font-size: 16px;
          margin-left: 46px;
        }
        .realname_middle {
          border: 1px solid transparent;
          margin-top: -5px;
          width: 200px;
          margin-left: 30px;
          outline: none;
          border-radius: 3px;
          height: 22px;
          padding:8px;
        }
        .realname_right {
          margin-left: 23px;
          color: red;
          font-size: 14px;
          cursor: pointer;
          margin-top: -1px;
        }
      }
      .setcompany {
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        .company_left {
          margin-left: 46px;
          font-size: 16px;
        }
        .company_middle {
          border: 1px solid transparent;
          width: 200px;
          font-size: 14px;
          color: #666666;
          margin-left: 30px;
          margin-top: 2px;
          padding: 8px;
        }
        .company_right {
          margin-left: 23px;
          color: red;
          font-size: 14px;
          cursor: pointer;
          margin-top: -1px;
        }
      }
      .setphone {
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        .phone_left {
          margin-left: 48px;
        }
        .phone_middle {
          border: 1px solid transparent;
          width: 100px;
          font-size: 14px;
          color: #666666;
          margin-left: 30px;
          padding: 8px;
        }
        .phone_right {
          font-size: 14px;
          margin-left: 23px;
          color: red;
          cursor: pointer;
          margin-top: -3px;
        }
      }
      .logout {
        margin-top: 10px;
        margin-left: 170px;
        border: 1px solid transparent;
      }
    }
  }
  .popup {
    width: 100%;
    height: 100%;
    background-color: rgba($color: rgb(66, 65, 65), $alpha: 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    // 修改姓名样式
    .updateName {
      width: 500px;
      height: 300px;
      background: #fff;
      border: 1px solid rgb(235, 231, 231);
      position: absolute;
      left: 37%;
      top: 30%;
      z-index: 99px;
      border-radius: 10px;
      .upCom_top {
        display: flex;
        justify-content: space-between;
        .upCom_text {
          margin-top: 15px;
          margin-left: 20px;
          font-size: 18px;
        }
        .close_btn {
          width: 40px;
          height: 40px;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
      .upCom_middle {
        display: flex;
        margin-top: 60px;
        .upCome_name {
          margin-left: 50px;
          font-size: 18px;
          margin-top: 7px;
          margin-right: 10px;
        }
        .el-input {
          width: 280px;
        }
      }
      .upCom_bottom {
        margin-top: 55px;
        margin-left: 130px;

        .sure {
          width: 80px;
          height: 40px;
          margin-left: 100px;
          background: #83b1fc;
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
        .cancel {
          width: 80px;
          height: 40px;
          background: rgb(216, 216, 221);
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
      }
    }
    // 修改单位样式
    .updateCompany {
      width: 500px;
      height: 300px;
      background: #fff;
      border: 1px solid rgb(235, 231, 231);
      position: absolute;
      left: 37%;
      top: 30%;
      z-index: 99px;
      border-radius: 10px;
      .upCom_top {
        display: flex;
        justify-content: space-between;
        .upCom_text {
          margin-top: 15px;
          margin-left: 20px;
          font-size: 18px;
        }
        .close_btn {
          width: 40px;
          height: 40px;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
      .upCom_middle {
        display: flex;
        margin-top: 60px;
        .upCome_name {
          margin-left: 50px;
          font-size: 18px;
          margin-top: 7px;
          margin-right: 10px;
        }
        .el-input {
          width: 280px;
        }
      }
      .upCom_bottom {
        margin-top: 55px;
        margin-left: 130px;

        .sure {
          width: 80px;
          height: 40px;
          margin-left: 100px;
          background: #83b1fc;
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
        .cancel {
          width: 80px;
          height: 40px;
          background: rgb(216, 216, 221);
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
      }
    }
    //修改手机样式
    .updatePhone {
      width: 500px;
      height: 400px;
      background: #fff;
      border: 1px solid rgb(235, 231, 231);
      position: absolute;
      left: 37%;
      top: 25%;
      z-index: 99px;
      border-radius: 10px;
      .upCom_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        .upCom_text {
          margin-top: 15px;
          margin-left: 20px;
          font-size: 18px;
        }
        .close_btn {
          width: 40px;
          height: 40px;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
      .upCom_middle {
        display: flex;
        margin-top: 25px;
        .upCome_name {
          margin-left: 50px;
          font-size: 16px;
          margin-top: 7px;
          margin-right: 10px;
        }
        .upCom_input_phone {
          width: 115px;
        }
        .getCode {
          background: #83b1fc;
          color: #fff;
          border-radius: 2px;
          width: 122px;
          height: 40px;
          margin-left: 70px;
          text-align: center;
          padding-top: 10px;
        }
        .upCom_input_code {
          width: 205px;
        }
        .upCome_input_new {
          width: 205px;
        }
      }
      .upCom_bottom {
        margin-top: 50px;
        margin-left: 130px;

        .sure {
          width: 80px;
          height: 40px;
          margin-left: 100px;
          background: #83b1fc;
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
        .cancel {
          width: 80px;
          height: 40px;
          background: rgb(216, 216, 221);
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
      }
    }
  }
}
.c-b {
  width: 0;
  height: 0;
  overflow: hidden;
  .certificate {
    width: 2300px;
    height: 1600px;
    background: url("../assets/images/cert.jpg") no-repeat;
    background-size: cover;
    position: relative;
    top: 100%;
    // font-size: 50px;
    left: 0;
    // z-index: -99;
    span {
      // font-size: 50px;
      // font-weight: 600;
      font-family: "宋体";
      position: absolute;
      color: #2e3441;
    }
    .cert-name {
      top: 54.5%;
      left: 15.5%;
      font-size: 65px;
      font-weight: 600;
    }
    .cert-stime {
      top: 54.5%;
      left: 29%;
      font-size: 65px;
      font-weight: 600;
    }
    .cert-etime {
      top: 54.5%;
      left: 48%;
      font-size: 65px;
      font-weight: 600;
    }
    .cert-course-time {
      top: 61.5%;
      left: 30.5%;
      font-size: 65px;
      font-weight: 600;
    }
    .cert-identifier {
      bottom: 14.8%;
      left: 18%;
      font-size: 45px;
      font-weight: 580;
    }
    .cert-ftime {
      left: 18%;
      bottom: 10.7%;
      font-size: 45px;
      font-weight: 580;
    }
    .cert-cname {
      right: 13%;
      bottom: 12%;
    }
  }
}
</style>
